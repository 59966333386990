import React from "react"
import ReactDOM from "react-dom/client"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

import "@assets/css/tailwind.css"
import "@assets/css/misc.scss"

import { Router } from "@app/router"
import { Providers } from "@app/providers"
import Config from "@app/config"

Sentry.init({
	dsn: "https://cf8630ffd4364048bda00e40b0045311@o4504012018614272.ingest.sentry.io/4504091068530688",
	environment: Config.env,
	enabled: ["staging", "production"].includes(Config.env),
	integrations: [
		new BrowserTracing({
			startTransactionOnLocationChange: false,
			startTransactionOnPageLoad: false,
		}),
	],
	release: Config.buildCommit,
	tracesSampleRate: 0.01,
})

// eslint-disable-next-line
ReactDOM.createRoot(document.getElementById("root")!).render(
	<React.StrictMode>
		<Providers>
			<Router />
		</Providers>
	</React.StrictMode>,
)
