import React from "react"
import { HelmetProvider } from "react-helmet-async"

import { ManagerProvider } from "@app/contexts"

export const Providers = ({ children }: { children: React.ReactNode }) => {
	return (
		<HelmetProvider>
			<ManagerProvider>{children}</ManagerProvider>
		</HelmetProvider>
	)
}
