import React from "react"
import { snakeCase, isEmpty, isArray, isString, first, camelCase, endsWith, size } from "lodash-es"
import short from "short-uuid"
import { v4 as uuidv4 } from "uuid"

const converter = short(short.constants.flickrBase58, { consistentLength: true })

export const newUUID = (): string => {
	return uuidv4()
}

export const humanize = (s: string): string => snakeCase(s).replace(/_/g, " ")

export const toUUID = (shortID?: string | string[] | null): string => {
	if (isEmpty(shortID)) {
		return ""
	}
	const id = (isArray(shortID) ? first(shortID) : shortID) || ""
	if (isString(id) && id.includes("-")) {
		return id
	}
	return converter.toUUID(id)
}

export const fromUUID = (uuid?: string): string => {
	if (!uuid) {
		return ""
	}
	return converter.fromUUID(uuid)
}

export function useDebouncedValue<T>(value: T, wait: number, options = { leading: false }) {
	const [_value, setValue] = React.useState(value)
	const mountedRef = React.useRef(false)
	const timeoutRef = React.useRef(0)
	const cooldownRef = React.useRef(false)

	const cancel = () => window.clearTimeout(timeoutRef.current)

	React.useEffect(() => {
		if (mountedRef.current) {
			if (!cooldownRef.current && options.leading) {
				cooldownRef.current = true
				setValue(value)
			} else {
				cancel()
				timeoutRef.current = window.setTimeout(() => {
					cooldownRef.current = false
					setValue(value)
				}, wait)
			}
		}
	}, [value, options.leading, wait])

	React.useEffect(() => {
		mountedRef.current = true
		return cancel
	}, [])

	return [_value, cancel] as const
}

export const pluralize = (entity: string): string => {
	const e = camelCase(entity)
	if (endsWith(e, "y")) {
		return `${e.substring(0, size(e) - 1)}ies`
	}
	return `${e}s`
}
