import React from "react"
import { first } from "lodash-es"

import { useManager } from "@app/contexts"
import { Alert } from "@app/components"

export const AlertContainer: React.FC = () => {
	const { alerts } = useManager()

	const alert = first(alerts)

	return (
		<div
			id="AlertContainer"
			className="w-full fixed h-full top-0 bottom-0 z-40 pointer-events-none"
		>
			<div className="m-2 space-y-2">{alert && <Alert key={alert.id} alert={alert} />}</div>
		</div>
	)
}
