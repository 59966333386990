import React from "react"
import { reduce, isNil, isEmpty, size, delay, filter, find, uniqueId, map } from "lodash-es"
import { validate as uuidValidate } from "uuid"
import useLocalStorageState from "use-local-storage-state"

import { managerContext } from "."
import { useRPC, GetBuildDetailsResult, AuthResult, OKResult } from "@app/hooks"
import Config from "@app/config"
import * as Sentry from "@sentry/browser"

import type { WorkDevice, Alert, Session, TrackingEvent, Entity } from "@app/domain"
import type { Transaction } from "@sentry/tracing"

type ManagerProviderProps = {
	children: React.ReactNode
}

const Second = 1000
const Minute = 60 * Second

const formatTimestamp = (n: number): string => {
	return new Date(n).toJSON()
}

export const ManagerProvider: React.FC<ManagerProviderProps> = (props) => {
	const { children } = props

	const { rpc, token, setToken } = useRPC()
	const [configured, setConfigured] = React.useState(false)
	const [started, setStarted] = React.useState(false)
	const [loading, setLoading] = React.useState(true)
	const [workDevice, setWorkDevice] = React.useState<WorkDevice>()
	const [alerts, setAlerts] = useLocalStorageState<Alert[]>("alerts", { defaultValue: [] })
	const [pendingEvents, setPendingEvents] = useLocalStorageState<TrackingEvent[]>("pendingEvents", {
		defaultValue: [],
	})
	const [submittedEvents, setSubmittedEvents] = useLocalStorageState<TrackingEvent[]>(
		"submittedEvents",
		{ defaultValue: [] },
	)
	const [tx, setTx] = React.useState<Transaction | null>(null)

	React.useEffect(() => {
		const onFocus = () => {
			setTx((prev: Transaction | null) => {
				if (prev) {
					prev.finish()
				}
				const next = Sentry.startTransaction({ name: "app#focus", op: "view" })
				Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(next))
				return next as Transaction
			})
		}
		const onBlur = () => {
			setTx((prev) => {
				if (prev) {
					prev.finish()
				}
				return null
			})
		}

		window.addEventListener("focus", onFocus, false)
		window.addEventListener("blur", onBlur, false)

		return () => {
			window.removeEventListener("focus", onFocus)
			window.removeEventListener("blur", onBlur)
		}
	}, [])

	React.useEffect(() => {
		const whoAmI = async () => {
			const resp = await rpc<AuthResult>("WhoAmI")
			if (resp.ok()) {
				setConfigured(true)
			} else {
				setPendingEvents([])
				setSubmittedEvents([])
				setToken("")
			}
			setLoading(false)
		}
		whoAmI()
	}, [token])

	React.useEffect(() => {
		let id: number

		const check = async () => {
			const resp = await rpc<GetBuildDetailsResult>("GetBuildDetails")
			if (resp.ok()) {
				const { buildDetails } = resp.result!
				if (buildDetails.commit !== Config.buildCommit) {
					// Immediately refresh when updates are detected.
					window.document.location.reload()
				}
			}
			schedule()
		}

		const schedule = () => {
			id = delay(check, 5 * Minute)
		}

		schedule()

		return () => {
			clearTimeout(id)
		}
	}, [])

	React.useEffect(() => {
		if (isEmpty(submittedEvents)) {
			return
		}

		const submitted = {}
		const params = map(submittedEvents, (e) => {
			submitted[e.id!] = true
			const workActionID = e.action!.id
			const correspondingID = null
			const subjectMetaData = {}
			const entityReducer = (acc: { [id: string]: string }, entity: Entity) => {
				const { id, type, description } = entity
				if (type === "Tag" && !uuidValidate(id)) {
					const tagURL = `https://os.toggle.industries/t/${description}`
					acc[tagURL] = "Tag"
					subjectMetaData[tagURL] = { tagURL }
				} else {
					acc[id] = type
				}
				return acc
			}

			const actors = reduce(e.actors, entityReducer, {})
			const locations = reduce(e.locations, entityReducer, {})
			const subjects = reduce(e.subjects, entityReducer, {})

			const reportedStart = isNil(e.reportedStart) ? null : formatTimestamp(e.reportedStart)
			const reportedFinish = isNil(e.reportedFinish) ? null : formatTimestamp(e.reportedFinish)
			const captured = formatTimestamp(e.captured!)

			return {
				workActionID,
				correspondingID,
				actors,
				locations,
				subjects,
				reportedStart,
				subjectMetaData,
				reportedFinish,
				captured,
			}
		})

		const submit = async () => {
			const resp = await rpc<OKResult>("PushWorkTrackingEvents", params)
			if (resp.ok()) {
				setSubmittedEvents((prev) => filter(prev, (e) => !submitted[e.id!]))
			}
		}

		submit()
	}, [size(submittedEvents)])

	const setSession = (session: Session) => {
		setToken(session.token)
		setWorkDevice(session.workDevice)
	}

	const addAlert = (newAlert: Alert) => {
		setAlerts((prev) => {
			const found = !!find(prev, (alert) => alert.id === newAlert.id)
			if (found) {
				return prev
			}
			const id = newAlert.id ? newAlert.id : uniqueId()
			return [...prev, { ...newAlert, id }]
		})
	}

	const removeAlert = (id?: string) => {
		if (id) {
			setAlerts((prev) => filter(prev, (alert) => alert.id !== id))
		}
	}

	const pushEvent = (event: TrackingEvent) => {
		setPendingEvents((prev) => {
			return [...prev, event]
		})
	}

	const removeEvent = (event: TrackingEvent) => {
		if (event.id) {
			setPendingEvents((prev) => filter(prev, (v) => v.id !== event.id))
		}
	}

	const submitEvent = (event: TrackingEvent) => {
		if (event.id) {
			setPendingEvents((prev) => filter(prev, (v) => v.id !== event.id))
		}
		setSubmittedEvents((prev) => [...prev, event])
	}

	return (
		<managerContext.Provider
			value={{
				addAlert,
				alerts,
				configured,
				loading,
				pendingEvents,
				pushEvent,
				removeAlert,
				removeEvent,
				setSession,
				setStarted,
				started,
				submitEvent,
				workDevice,
				tx,
			}}
		>
			{children}
		</managerContext.Provider>
	)
}
