import {
	assign,
	compact,
	first,
	isArray,
	isEmpty,
	isEqual,
	isNil,
	isObject,
	isString,
	map,
	size,
	uniqueId,
	upperFirst,
} from "lodash-es"
import useLocalStorageState from "use-local-storage-state"

export * from "./results"

type Headers = { [name: string]: string }

type Options = {
	headers?: Headers
}

type RPCErrorProps = {
	name: string | null
	message: string | null
	details: string[]
}

export class RPCError extends Error {
	details: string[] = []

	constructor(args: { message: string; name: string; details: string[] }) {
		super(args.message)
		this.name = upperFirst(args.name)
		this.details = args.details || []
	}
}

export class HTTPError extends Error {
	constructor(args: { message: string; name: string }) {
		super(args.message)
		this.name = upperFirst(args.name)
	}
}

type RPCResponseProps = {
	error: RPCErrorProps
	method: string
	id: string
	result: unknown
}

type RPCResult<T> = T & { ok: boolean }

export class RPCResponse<T> {
	error: RPCErrorProps = {
		name: null,
		message: null,
		details: [],
	}

	id: string

	method: string

	result: RPCResult<T> | undefined = undefined

	constructor(props: RPCResponseProps) {
		if (props.error) {
			this.error = props.error
		}
		this.id = props.id
		this.method = props.method
		this.result = props.result as RPCResult<T>
	}

	equal(other?: RPCResponse<T>): boolean {
		if (isNil(other)) {
			return false
		}
		if (this.method !== other.method) {
			return false
		}
		return isEqual(this.result, other.result)
	}

	ok(): boolean {
		return this.result?.ok ?? false
	}

	getResult(): RPCResult<T> {
		return this.result as RPCResult<T>
	}

	errorMessage(): string | null {
		return this.error.message
	}

	unauthorized(): boolean {
		return this.error.name === "unauthorized"
	}
}

export const useRPC = (endpoint = "/rpc/tracking") => {
	const [token, setToken] = useLocalStorageState("sessionToken", { defaultValue: "" })

	return {
		token,
		setToken,
		rpc: async <T>(
			method: string,
			params: unknown = {},
			options: Options = {},
		): Promise<RPCResponse<T>> => {
			const id = uniqueId()
			const body = {
				id,
				method,
				params,
			}
			const headers = assign({}, options.headers || {})
			if (!isEmpty(token)) {
				headers["X-Toggle-Tracking-Session"] = token
			}
			const controller = new AbortController()
			const timeoutID = setTimeout(() => controller.abort(), 5000)
			let hint = ""
			if (import.meta.env.DEV) {
				if (isObject(params)) {
					const keys = Object.keys(params).sort()
					hint = compact(
						map(keys, (k) => {
							const v = params[k]
							if (isNil(v)) {
								return null
							}
							if (isArray(v)) {
								if (size(v) > 1) {
									return `${k}=[${first(v)},…${size(v)} more]`
								} else if (size(v) === 0) {
									return `${k}=[]`
								}
								return `${k}=[${first(v)}]`
							}
							if (isObject(v)) {
								return null
							}
							if (isString(k) && k.toLowerCase() === "password") {
								return null
							}
							return `${k}=${v}`
						}),
					).join("&")
				} else if (isString(params)) {
					hint = params
				}
			}
			try {
				const resp = await fetch(`${endpoint}?${method}${hint ? `(${hint})` : ""}`, {
					method: "post",
					body: JSON.stringify(body),
					headers,
					signal: controller.signal,
				})
				if (resp.status !== 200) {
					return new RPCResponse({
						id,
						method,
						error: {
							details: [],
							message: `bad status: want 200, got ${resp.status}`,
							name: "http",
						},
						result: {},
					})
				}
				try {
					const data = await resp.json()
					const result = new RPCResponse<T>({ id, method, ...data })
					if (result.unauthorized()) {
						setToken("")
					}
					return result
				} catch (err) {
					console.error("rpc error:", err)
					return new RPCResponse({
						id,
						method,
						error: {
							details: [],
							message: `${err}`,
							name: "json_parse",
						},
						result: {},
					})
				}
			} catch (err) {
				console.error("rpc error:", err)
				return new RPCResponse({
					id,
					method,
					error: {
						details: [],
						message: `${err}`,
						name: "unknown",
					},
					result: {},
				})
			} finally {
				clearTimeout(timeoutID)
			}
		},
	}
}
