import React from "react"
import { first, upperFirst, lowerFirst, delay } from "lodash-es"
import clsx from "clsx"
import { format } from "date-fns"

import Config from "@app/config"
import { useManager } from "@app/contexts"
import { Icon } from "@app/components"
import { humanize } from "@app/util"

import { AlertLevel, TrackingEvent } from "@app/domain"

type PendingEventProps = {
	event: TrackingEvent
}

export const PendingEvent: React.FC<PendingEventProps> = (props) => {
	const { event } = props

	const { submitEvent, addAlert, removeEvent } = useManager()

	const [tick, setTick] = React.useState(0)

	const { id } = event
	const timeout = Config.undoEventTimeout

	React.useEffect(() => {
		let timeout: number

		const fire = async () => {
			setTick((prev) => prev + 250)
			schedule()
		}

		const schedule = () => {
			timeout = delay(fire, 250)
		}

		schedule()

		return () => {
			clearTimeout(timeout)
		}
	}, [id, timeout])

	React.useEffect(() => {
		if (tick > timeout + 250) {
			submitEvent(event)
		}
	}, [id, timeout, tick])

	const width = tick >= 250 ? "w-0" : "w-full"
	const opacity = tick >= timeout + 250 || tick < 250 ? "opacity-0" : "opacity-100"
	const transition = `width ${timeout}ms linear`

	const action = event.action!
	const actionDescription =
		action.type === "Timestamp"
			? action.description
			: `${action.description} ${action.type.toLowerCase()}`
	const location = first(event.locations)!
	const subject = first(event.subjects)!
	const when = event.reportedStart ? event.reportedStart : event.reportedFinish

	const type = subject.type === "Worker" ? "" : humanize(subject.type)
	const description = upperFirst(`${type} ${subject.description} in ${location.description}.`)
	const title = upperFirst(`${actionDescription} at ${format(when!, "h:mm:ss aa")}`)

	return (
		<div className={clsx(opacity, "transition duration-250 bg-gray-900 shadow-lg")}>
			<div className="flex relative">
				<div className={clsx("flex p-4 items-center", "bg-yellow-500")}>
					<Icon name="TrackingEvent" className="h-6 w-6 text-white" />
				</div>
				<div className="flex-grow m-4">
					<h3 className={clsx("text-md font-bold text-yellow-400")}>{title}</h3>
					<div className="mt-1 text-sm text-gray-100">{description}</div>
				</div>
				<>
					<button
						className="flex items-center px-2 text-gray-300 hover:text-gray-500"
						onClick={(e) => {
							e.preventDefault()
							removeEvent(event)
							addAlert({
								title: "Event submit canceled",
								text: `"${title}—${lowerFirst(description)}" will not be recorded.`,
								level: AlertLevel.Info,
							})
							return false
						}}
					>
						<Icon name="Cancel" className="h-5 w-5" />
					</button>
					<div className="absolute left-0 bottom-0 w-full h-1 bg-black bg-opacity-50">
						<div className={clsx("h-1 bg-yellow-600", width)} style={{ transition }}></div>
					</div>
				</>
			</div>
		</div>
	)
}
