import React from "react"
import clsx from "clsx"
import { size, noop, upperFirst, first, map, isEmpty, delay } from "lodash-es"
import { format } from "date-fns"
import ReactSlider from "react-slider"

import { Badge, Icon } from "@app/components"
import { humanize } from "@app/util"
import type { Entity } from "@app/domain"

type SlotType = "Subject" | "Actor" | "Action" | "Location" | "Time"

type SlotProps = {
	disabled: boolean
	entities?: Entity[]
	minuteRange?: number
	onClear?: () => void
	onPinClick?: () => void
	pinned?: boolean
	setTime?: React.Dispatch<React.SetStateAction<number>>
	setTimeOffset?: React.Dispatch<React.SetStateAction<number>>
	time?: number
	timeOffset?: number
	type: SlotType
}

const Placeholders = {
	Action: "Why",
	Actor: "Who",
	Location: "Where",
	Subject: "What",
	Time: "When",
}

export const Slot: React.FC<SlotProps> = (props) => {
	const {
		disabled = false,
		entities = [],
		minuteRange = 30,
		onClear,
		onPinClick,
		pinned = false,
		setTime = noop,
		setTimeOffset = noop,
		time = Date.now(),
		timeOffset = minuteRange,
		type,
	} = props

	const timeModified = timeOffset !== minuteRange
	const [flash, setFlash] = React.useState<boolean>(false)

	React.useEffect(() => {
		if (!flash) {
			return
		}
		const id = setTimeout(() => setFlash(false), 800)
		return () => {
			clearTimeout(id)
		}
	}, [flash])

	React.useEffect(() => {
		if (!isEmpty(entities)) {
			setFlash(true)
		}
	}, [size(entities)])

	React.useEffect(() => {
		if (type !== "Time" || timeModified) {
			return
		}
		let id: number

		const check = async () => {
			setTime(Date.now())
			schedule()
		}

		const schedule = () => {
			id = delay(check, 1000 - (time % 1000))
		}

		schedule()

		return () => {
			clearTimeout(id)
		}
	}, [timeOffset])

	React.useEffect(() => {
		if (timeModified) {
			const ts = Date.now() - (minuteRange - timeOffset) * 60000
			setTime(ts - (ts % 60000))
		} else {
			setTime(Date.now())
		}
	}, [timeOffset])

	let filled = type === "Time"
	let content: React.ReactNode[] = [Placeholders[type]]
	if (type === "Time") {
		const minAgo = minuteRange - timeOffset
		content = [
			<div key="time" className="flex-row bg-white w-full">
				<div className="w-full font-mono text-sm grow items-center inline-flex p-2 justify-center">
					{format(time, timeModified ? "h:mm aa" : "h:mm:ss aa")}
					{timeModified && <span>&nbsp;{`(${minAgo} min ago)`}</span>}
				</div>
				<div className="flex w-full pb-4">
					<div className="w-full mx-2">
						<ReactSlider
							disabled={disabled}
							min={0}
							max={minuteRange}
							value={timeOffset}
							onChange={setTimeOffset}
							className="slider"
							thumbClassName="slider-thumb"
							trackClassName="slider-track"
							renderThumb={(props) => (
								<div {...props}>
									<div className="w-4 h-4 rounded-full absolute top-1 left-1 bg-white"></div>
								</div>
							)}
						/>
					</div>
				</div>
			</div>,
		]
	} else if (!isEmpty(entities)) {
		filled = true
		content = [
			<span key="description">{map(entities, (e) => upperFirst(e.description)).join(", ")}</span>,
		]
		if (type === "Action") {
			content.push(<Badge key="type">{first(entities)!.type}</Badge>)
		} else if (type === "Location" || type === "Subject") {
			const label = humanize(first(entities)!.type)
			if (label !== "worker") {
				content.push(<Badge key="type">{label}</Badge>)
			}
		}
	}

	const textColor = disabled ? "text-gray-300" : filled ? "text-gray-700" : "text-gray-500"
	const bgColor = disabled ? "bg-gray-400" : filled ? "bg-green-500" : "bg-red-500"
	const opacity = filled ? "opacity-100" : "opacity-50"
	const clearable = filled && type !== "Time" && !pinned

	return (
		<div
			className={clsx(
				flash && "flash-slot",
				"relative flex w-full bg-white rounded-md",
				pinned ? "outline outline-green-500 outline-2" : "outline outline-transparent outline-2",
			)}
		>
			<button
				className={clsx("relative inline-flex items-center p-3 rounded-md", bgColor)}
				onClick={onPinClick}
			>
				<Icon
					name={type}
					className={clsx("h-8 w-8 text-white", opacity, timeModified && "flash")}
				/>
				{!disabled && (
					<Icon
						name={pinned ? "Thumbtack" : filled ? "CheckCircle" : "XCircle"}
						className={clsx(
							"absolute right-1 bottom-1 h-4 w-4 pointer-events-none",
							filled && "text-green-800",
						)}
					/>
				)}
			</button>
			<div
				className={clsx(
					textColor,
					"bg-white grow items-center inline-flex items-center px-2 rounded-md",
				)}
			>
				<div className="text-center w-full">{content}</div>
			</div>
			{clearable ? (
				<button className="inline-flex items-center text-gray-400" onClick={onClear}>
					<Icon name="X" className="w-5 h-5 pr-2" />
				</button>
			) : type === "Time" ? null : (
				<div className="inline-flex items-center text-gray-400 w-7"></div>
			)}
		</div>
	)
}
