import React from "react"
import { compact } from "lodash-es"
import { Helmet } from "react-helmet-async"

import Config from "@app/config"

type LayoutProps = {
	children?: React.ReactNode
	title?: string
}

export const Layout: React.FC<LayoutProps> = (props) => {
	const { children, title } = props
	return (
		<>
			<Helmet>
				<title>{compact([title, "Toggle Production Tracking"]).join(" | ")}</title>
				<link rel="icon" href={`/assets/img/${Config.env}-favicon.png`} />
			</Helmet>
			<div className="mx-auto">
				<div className="bg-gray-100">{children}</div>
			</div>
		</>
	)
}
