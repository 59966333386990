import React from "react"
import clsx from "clsx"
import { map, size, first, trim, isNil, uniqBy, isEmpty, reduce, each } from "lodash-es"
import { useDebouncedValue } from "@app/util"
import { QrReader } from "react-qr-reader"
import { Layout } from "@app/layouts"

import { AlertContainer, Icon, Logo, PendingEvent, Slot } from "@app/components"
import { useManager } from "@app/contexts"
import { AlertLevel, Entity, TrackingEvent, parseQRCode } from "@app/domain"
import { useRPC, AuthResult } from "@app/hooks"
import { newUUID } from "@app/util"

const minuteRange = 30

enum FlashState {
	None,
	Success,
	Error,
}

const vibrate = (pattern: number | number[]) => {
	if (window.navigator.vibrate) {
		window.navigator.vibrate(pattern)
	}
}

export const RootPage: React.FC = () => {
	const [text, setText] = React.useState<string | undefined>()

	const { rpc } = useRPC()
	const {
		addAlert,
		configured,
		loading,
		pendingEvents,
		pushEvent,
		removeAlert,
		setSession,
		setStarted,
		started,
	} = useManager()

	const [action, setAction] = React.useState<Entity>()
	const [subjects, setSubjects] = React.useState<Entity[]>([])
	const [actors, setActors] = React.useState<Entity[]>([])
	const [locations, setLocations] = React.useState<Entity[]>([])

	const [pinned, setPinned] = React.useState<{ [key: string]: boolean }>({})
	const [flash, setFlash] = React.useState<FlashState>(FlashState.None)
	const [time, setTime] = React.useState(Date.now())
	const [timeOffset, setTimeOffset] = React.useState(minuteRange)

	React.useEffect(() => {
		if (flash === FlashState.None) {
			return
		} else if (flash === FlashState.Success) {
			vibrate([100, 50, 100])
		} else if (flash === FlashState.Error) {
			vibrate(500)
		}
		const id = setTimeout(() => setFlash(FlashState.None), 400)
		return () => {
			clearTimeout(id)
		}
	}, [flash])

	React.useEffect(() => {
		const registerDevice = async (id: string, secret: string, tenantID: number) => {
			const resp = await rpc<AuthResult>("RegisterDevice", { id, secret, tenantID })
			if (resp.ok()) {
				const { session } = resp.result!
				const { workDevice } = session
				setSession(session)
				removeAlert("unconfigured")
				if (workDevice.inventoryTag) {
					addAlert({
						title: "Device configured",
						text: `Ensure the device tag matches "${workDevice.inventoryTag}".`,
						level: AlertLevel.Success,
						timeout: 30000,
					})
				}
			} else {
				addAlert({
					title: "Configuration failure",
					text: "Code was unauthorized or no longer valid.",
					level: AlertLevel.Error,
				})
			}
		}

		if (isEmpty(text)) {
			return
		}

		try {
			const [kind, result] = parseQRCode(text)

			if (kind === "Config") {
				const { id, secret, tenantID } = result
				registerDevice(id, secret, tenantID)
				setFlash(FlashState.Success)
				return
			}

			if (loading || !configured) {
				setFlash(FlashState.Error)
				return
			}

			if (kind === "Event") {
				const { action, actors, locations, subjects } = result
				if (action) {
					if (pinned["Action"]) {
						setFlash(FlashState.Error)
						addAlert({
							text: `"Why" is pinned, unpin if you want to replace it.`,
							title: "Scan ignored",
							level: AlertLevel.Error,
							timeout: 2500,
						})
						return
					} else {
						setAction(action)
					}
				}
				if (actors) {
					if (pinned["Actor"]) {
						setFlash(FlashState.Error)
						addAlert({
							text: `"Who" is pinned, unpin if you want to replace it.`,
							title: "Scan ignored",
							level: AlertLevel.Error,
							timeout: 2500,
						})
						return
					} else {
						setActors((prev) => {
							return uniqBy(prev.concat(actors), "id")
						})
					}
				}
				if (locations) {
					if (pinned["Location"]) {
						setFlash(FlashState.Error)
						addAlert({
							text: `"Where" is pinned, unpin if you want to replace it.`,
							title: "Scan ignored",
							level: AlertLevel.Error,
							timeout: 2500,
						})
						return
					} else {
						setLocations(locations)
					}
				}
				if (subjects) {
					if (pinned["Subject"]) {
						setFlash(FlashState.Error)
						addAlert({
							text: `"What" is pinned, unpin if you want to replace it.`,
							title: "Scan ignored",
							level: AlertLevel.Error,
							timeout: 2500,
						})
						return
					} else {
						setSubjects((prev) => {
							return uniqBy(prev.concat(subjects), "id")
						})
					}
				}
				setFlash(FlashState.Success)
				return
			}
		} catch (e) {
			// fallthrough
		}

		setFlash(FlashState.Error)
		addAlert({
			title: "Scan failure",
			text: "Bad scan or invalid code, try again.",
			level: AlertLevel.Error,
			timeout: 1000,
		})
	}, [text])

	const actorIDs = map(actors, (actor) => actor.id).join(",")

	React.useEffect(() => {
		if (action?.actorIsSubject) {
			setSubjects(isEmpty(actors) ? [] : actors)
		}
	}, [actorIDs])

	React.useEffect(() => {
		if (action?.actorIsSubject && !isEmpty(actors)) {
			setSubjects(actors)
		}
	}, [action?.id])

	// Allow the scanning of the same code after 5 seconds.
	React.useEffect(() => {
		if (text === "") {
			return
		}
		const id = setTimeout(() => {
			setText("")
		}, 5000)
		return () => {
			clearTimeout(id)
		}
	}, [text])

	React.useEffect(() => {
		if (!loading && !configured) {
			addAlert({
				id: "unconfigured",
				text: "This device is not configured. Scan a device configuration code to continue.",
				title: "Missing configuration",
				level: AlertLevel.Error,
				sticky: true,
			})
		}
	}, [loading, configured])

	const [fulfilled] = useDebouncedValue(
		!isNil(action) && !isEmpty(subjects) && !isEmpty(locations) && !isEmpty(actors),
		500,
	)

	React.useEffect(() => {
		if (!fulfilled) {
			return
		}

		const timeModified = timeOffset !== minuteRange
		const captured = timeModified ? Date.now() : time
		const id = newUUID()

		const event: TrackingEvent = {
			id,
			action,
			actors,
			captured,
			locations,
			subjects,
		}

		if (["Start", "Timestamp"].includes(event.action!.type)) {
			event.reportedStart = time
		}
		if (["Finish", "Timestamp"].includes(event.action!.type)) {
			event.reportedFinish = time
		}

		pushEvent(event)
		setText("")

		if (!pinned["Action"]) {
			setAction(undefined)
		}
		if (!pinned["Subject"]) {
			setSubjects([])
		}
		if (!pinned["Actor"]) {
			setActors([])
		}
		if (!pinned["Location"]) {
			setLocations([])
		}
		setTime(Date.now())
		setTimeOffset(minuteRange)
	}, [fulfilled])

	const disabled = loading || !configured

	let debug = null
	if (import.meta.env.DEV) {
		const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
			if (e.code === "Enter") {
				// Support pasting multiple URLs delimited by commas.
				const urls = e.currentTarget.value.split(",")
				each(urls, (url, i) => {
					setTimeout(() => {
						setText(trim(url))
					}, i * 100)
				})
				e.currentTarget.value = ""
			}
		}
		debug = (
			<div className={clsx("absolute left-0 w-full z-30 p-2", disabled ? "bottom-0" : "top-0")}>
				<Icon name="QRCode" className="absolute top-4 left-4" />
				<input
					className="w-full pl-8 pr-2 h-8 text-sm focus:outline-none"
					type="text"
					name="url"
					onKeyDown={onKeyDown}
					placeholder="https://..."
				/>
			</div>
		)
	}

	if (loading || !started) {
		return (
			<Layout>
				<div className="absolute left-0 top-0 w-full flex flex-row h-full items-center justify-center">
					<div className="flex flex-col items-center">
						<Logo width={128} />
						<h1 className="mt-2 text-2xl font-bold border-b-4 border-red-500">
							Production Tracking
						</h1>
						<button
							disabled={loading}
							className={clsx(
								loading ? "text-gray-300 bg-gray-400" : "text-white bg-gray-500",
								"flex items-center px-8 p-3  rounded-md mt-8 font-bold uppercase text-2xl",
							)}
							onClick={() => setStarted(true)}
						>
							Start
							<Icon name="ArrowRight" className={clsx(!loading && "pulse", "w-6 h-6 ml-2")} />
						</button>
					</div>
				</div>
			</Layout>
		)
	}

	return (
		<Layout>
			<div
				id="FlashContainer"
				className={clsx(
					flash === FlashState.None && "hidden",
					flash === FlashState.Error && "border-red-500 bg-red-500",
					flash === FlashState.Success && "border-green-500 bg-green-500",
					"flash fixed w-full h-full border-8 z-50 bg-opacity-25 pointer-events-none",
				)}
			></div>
			<AlertContainer />
			<QrReader
				constraints={{ facingMode: "environment", aspectRatio: 1920 / 1080 }}
				scanDelay={100}
				onResult={(result, error) => {
					if (!error && result) {
						setText(result.getText())
					}
				}}
				className="bg-black z-10"
				videoContainerStyle={{ paddingTop: "60%" }}
			/>
			{debug}
			{disabled && (
				<div className="absolute left-0 top-0 w-full flex flex-row h-full items-center justify-center">
					<Logo width={128} />
				</div>
			)}
			{!disabled && (
				<>
					<div className="p-2 bg-gray-300 space-y-2">
						<Slot
							disabled={disabled}
							type="Location"
							pinned={pinned["Location"]}
							entities={locations}
							onClear={() => {
								setLocations([])
							}}
							onPinClick={() => {
								setPinned((prev) => {
									const count = reduce(prev, (acc, v) => (v ? acc + 1 : acc), 0)
									const next = !prev.Location
									if (next && count === 3) {
										return prev
									}
									if (!isEmpty(locations) || !next) {
										return { ...prev, Location: next }
									}
									return prev
								})
							}}
						/>
						<Slot
							disabled={disabled}
							type="Actor"
							pinned={pinned["Actor"]}
							entities={actors}
							onClear={() => {
								setActors([])
							}}
							onPinClick={() => {
								setPinned((prev) => {
									const count = reduce(prev, (acc, v) => (v ? acc + 1 : acc), 0)
									const next = !prev.Actor
									if (next && count === 3) {
										return prev
									}
									if (!isEmpty(actors) || !next) {
										return { ...prev, Actor: next }
									}
									return prev
								})
							}}
						/>
						<Slot
							disabled={disabled}
							type="Subject"
							pinned={pinned["Subject"]}
							entities={subjects}
							onClear={() => {
								setSubjects([])
							}}
							onPinClick={() => {
								setPinned((prev) => {
									const count = reduce(prev, (acc, v) => (v ? acc + 1 : acc), 0)
									const next = !prev.Subject
									if (next && count === 3) {
										return prev
									}
									if (!isEmpty(subjects) || !next) {
										return { ...prev, Subject: next }
									}
									return prev
								})
							}}
						/>
						<Slot
							disabled={disabled}
							type="Action"
							pinned={pinned["Action"]}
							entities={action && [action]}
							onClear={() => {
								setAction(undefined)
							}}
							onPinClick={() => {
								setPinned((prev) => {
									const count = reduce(prev, (acc, v) => (v ? acc + 1 : acc), 0)
									const next = !prev.Action
									if (next && count === 3) {
										return prev
									}
									if (action || !next) {
										return { ...prev, Action: next }
									}
									return prev
								})
							}}
						/>
						<Slot
							disabled={disabled}
							type="Time"
							setTime={setTime}
							time={time}
							setTimeOffset={setTimeOffset}
							timeOffset={timeOffset}
						/>
					</div>
					<div className="fixed w-full px-2 pb-2 bottom-0 z-40">
						{first(pendingEvents) && (
							<PendingEvent key={first(pendingEvents)!.id} event={first(pendingEvents)!} />
						)}
						{size(pendingEvents) >= 2 && (
							<div className="absolute right-0 -top-4 bg-yellow-500 text-black font-bold w-8 h-8 rounded-full inline-flex justify-center items-center shadow-sm">
								{size(pendingEvents) - 1}
							</div>
						)}
					</div>
				</>
			)}
		</Layout>
	)
}
