import { trim, startsWith, map, size, each } from "lodash-es"
import { toUUID } from "@app/util"

export enum AlertLevel {
	Info = "Info",
	Error = "Error",
	Success = "Success",
}

export type Alert = {
	id?: string
	text: string
	title: string
	level: AlertLevel
	sticky?: boolean
	timeout?: number
}

export type Session = {
	token: string
	workDevice: WorkDevice
}

export type WorkDevice = {
	id: string
	workFacilityID: string
	description: string
	disabled: boolean
	inventoryTag: string
	workDeviceType: string
	created: string
	updated: string
}

export interface Entity {
	id: string
	type: string
	description?: string
	actorIsSubject?: boolean
}

export type TrackingEvent = {
	id?: string

	action?: Entity

	actors?: Entity[]
	locations?: Entity[]
	subjects?: Entity[]

	reportedStart?: number
	reportedFinish?: number
	captured?: number

	correspondingID?: string
}

export type ConfigureDevice = {
	id: string
	secret: string
	tenantID: number
}

type EncodedEntity = {
	id: string // id
	t: string // type
	d?: string // description
}

type EncodedTrackingEvent = {
	z?: EncodedEntity // action

	a?: EncodedEntity[] // actors
	l?: EncodedEntity[] // locations
	s?: EncodedEntity[] // subjects
}

const referenceAlphabet = "ABCDEFGHJKLMNPQRSTUVWXYZ1234567890"

const legacyTagReferenceIDMatcher = new RegExp(`T[${referenceAlphabet}]{6}`)
const multiTenantTagReferenceIDMatcher = new RegExp(`([\\d+])T[${referenceAlphabet}]{5}`)

export const parseQRCode = (data = ""): ["Config", ConfigureDevice] | ["Event", TrackingEvent] => {
	const url = new URL(data)
	const { pathname } = url

	if (startsWith(pathname, "/pt/config/")) {
		const [, , shortID, secret, tid] = trim(pathname, "/").split("/")
		const id = toUUID(shortID)
		const tenantID = Number(tid) || 0
		return ["Config", { id, secret, tenantID }]
	}

	if (startsWith(pathname, "/t/")) {
		const parts = pathname.substring(3).split("-")
		if (size(parts) !== 2) {
			throw new Error("reference id or unit is missing")
		}
		const referenceID = parts[0]
		const unit = parseInt(parts[1])
		if (
			!referenceID.match(legacyTagReferenceIDMatcher) &&
			!referenceID.match(multiTenantTagReferenceIDMatcher)
		) {
			throw new Error("invalid reference id")
		}
		if (isNaN(unit)) {
			throw new Error("unit is not an integer")
		}

		const subjects: Entity[] = [
			{
				id: referenceID,
				description: `${referenceID}-${unit}`,
				type: "Tag",
			},
		]

		const event: TrackingEvent = { subjects }

		return ["Event", event]
	}

	if (startsWith(pathname, "/pt/")) {
		const [, base64] = trim(pathname, "/").split("/")
		const encoded = JSON.parse(window.atob(base64)) as EncodedTrackingEvent

		let action: Entity | undefined
		let actors: Entity[] | undefined
		let locations: Entity[] | undefined
		let subjects: Entity[] | undefined

		if (encoded.z) {
			const details = (encoded.z.t || "").split(",")
			let type = "Timestamp"
			let actorIsSubject = false

			each(details, (d) => {
				if (d === "s") {
					type = "Start"
				} else if (d === "f") {
					type = "Finish"
				} else if (d === "a") {
					actorIsSubject = true
				}
			})
			action = {
				id: toUUID(encoded.z.id),
				type,
				description: encoded.z.d,
				actorIsSubject,
			}
		}

		if (encoded.a) {
			actors = map(encoded.a, (entity) => {
				const { t: type, d: description } = entity
				return {
					id: toUUID(entity.id),
					type,
					description,
				}
			})
		}
		if (encoded.l) {
			locations = map(encoded.l, (entity) => {
				const { t: type, d: description } = entity
				return {
					id: toUUID(entity.id),
					type,
					description,
				}
			})
		}
		if (encoded.s) {
			subjects = map(encoded.s, (entity) => {
				const { t: type, d: description } = entity
				return {
					id: toUUID(entity.id),
					type,
					description,
				}
			})
		}

		const event: TrackingEvent = {
			action,
			actors,
			locations,
			subjects,
		}

		return ["Event", event]
	}
	throw new Error("invalid tracking code")
}
