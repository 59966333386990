import React from "react"
import { noop } from "lodash-es"

import type { WorkDevice, Session, Alert, TrackingEvent } from "@app/domain"
import type { Transaction } from "@sentry/tracing"

export type ManagerContext = {
	addAlert: (alert: Alert) => void
	alerts: Alert[]
	configured: boolean
	loading: boolean
	pendingEvents: TrackingEvent[]
	pushEvent: (event: TrackingEvent) => void
	removeAlert: (id?: string) => void
	removeEvent: (event: TrackingEvent) => void
	setSession: (session: Session) => void
	setStarted: (v: boolean) => void
	started: boolean
	submitEvent: (event: TrackingEvent) => void
	tx: Transaction | null
	workDevice: WorkDevice | undefined
}

export const managerContext = React.createContext<ManagerContext>({
	addAlert: noop,
	alerts: [],
	configured: false,
	loading: true,
	pendingEvents: [],
	pushEvent: noop,
	removeAlert: noop,
	removeEvent: noop,
	setSession: noop,
	setStarted: noop,
	started: false,
	submitEvent: noop,
	tx: null,
	workDevice: undefined,
})

export const useManager = (): ManagerContext => React.useContext(managerContext)
