import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faCircleArrowRight,
	faBan,
	faCheckCircle,
	faCircleXmark,
	faClipboardCheck,
	faClock,
	faHammer,
	faLocationDot,
	faPenToSquare,
	faQrcode,
	faSquareFull,
	faThumbtack,
	faWifi,
	faXmark,
} from "@fortawesome/free-solid-svg-icons"

import type { FontAwesomeIconProps } from "@fortawesome/react-fontawesome"

const Icons = {
	ArrowRight: faCircleArrowRight,
	Action: faHammer,
	CheckCircle: faCheckCircle,
	Edit: faPenToSquare,
	Cancel: faBan,
	Location: faLocationDot,
	QRCode: faQrcode,
	Thumbtack: faThumbtack,
	Time: faClock,
	TrackingEvent: faClipboardCheck,
	Wifi: faWifi,
	X: faXmark,
	XCircle: faCircleXmark,
}

const Subject = ({ className = "" }) => (
	<svg
		className={className}
		fill="currentColor"
		height="200"
		viewBox="0 0 200 200"
		width="200"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M199.936 38.5883C199.936 34.4086 196.55 31.0227 192.37 31.0227H172.917V46.1539H192.37C196.55 46.1539 199.936 42.7631 199.936 38.5883Z" />
		<path d="M192.375 92.4883H172.922V107.62H192.375C196.554 107.62 199.94 104.234 199.94 100.054C199.935 95.8742 196.55 92.4883 192.375 92.4883Z" />
		<path d="M192.375 153.949H172.922V169.08H192.375C196.554 169.08 199.94 165.694 199.94 161.515C199.935 157.335 196.55 153.949 192.375 153.949Z" />
		<path d="M161.568 0.221313C157.389 0.221313 154.003 3.60721 154.003 7.78691V31.0227H112.397V46.1539H154.003V92.4883H112.397V107.62H154.003V153.954H112.397V169.085H154.003V192.321C154.003 196.501 157.389 199.887 161.568 199.887C165.748 199.887 169.134 196.501 169.134 192.321V7.78691C169.134 3.60721 165.748 0.221313 161.568 0.221313Z" />
		<path d="M100.108 0.221313C95.9281 0.221313 92.5422 3.60721 92.5422 7.78691V31.0227H50.9363V46.1539H92.5422V92.4883H50.9363V107.62H92.5422V153.954H50.9363V169.085H92.5422V192.321C92.5422 196.501 95.9281 199.887 100.108 199.887C104.288 199.887 107.673 196.501 107.673 192.321V7.78691C107.673 3.60721 104.283 0.221313 100.108 0.221313Z" />
		<path d="M38.6422 0.221313C34.4625 0.221313 31.0766 3.60721 31.0766 7.78691V31.0227H7.8408C3.6611 31.0227 0.275204 34.4086 0.275204 38.5883C0.275204 42.768 3.6611 46.1539 7.8408 46.1539H31.0766V92.4883H7.8408C3.6611 92.4883 0.275204 95.8742 0.275204 100.054C0.275204 104.234 3.6611 107.62 7.8408 107.62H31.0766V153.954H7.8408C3.6611 153.954 0.275204 157.34 0.275204 161.52C0.275204 165.699 3.6611 169.085 7.8408 169.085H31.0766V192.321C31.0766 196.501 34.4625 199.887 38.6422 199.887C42.8219 199.887 46.2078 196.501 46.2078 192.321V7.78691C46.2078 3.60721 42.8219 0.221313 38.6422 0.221313Z" />
	</svg>
)

const Worker = ({ className = "" }) => (
	<svg
		className={className}
		fill="currentColor"
		height="41"
		viewBox="0 0 36 41"
		width="36"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="22.7772" height="3.7962" transform="matrix(-1 0 0 1 29.5435 11.2723)" />
		<rect width="4.7221" height="3.70361" transform="matrix(-1 0 0 1 20.516 0.716997)" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.35785 17.661H27.9521C27.2371 22.4298 23.1231 26.0867 18.155 26.0867C13.1869 26.0867 9.07289 22.4298 8.35785 17.661ZM8.34442 14.7907H27.9655C27.2902 9.97668 23.1552 6.27241 18.155 6.27241C13.1548 6.27241 9.01976 9.97668 8.34442 14.7907Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.9 28.3717C13.6056 29.7619 15.783 30.5955 18.155 30.5955C20.527 30.5955 22.7043 29.7619 24.4099 28.3717V40.1323L18.155 40.1323H0.562855C0.285084 37.9719 0.785072 32.8362 5.00718 29.5771C6.02551 28.791 7.16485 28.3051 8.3444 28.0394V40.1323H11.9V28.3717ZM31.3028 29.5771C30.2844 28.791 29.1451 28.3051 27.9655 28.0394V40.1323L35.7471 40.1324C36.0249 37.9719 35.5249 32.8362 31.3028 29.5771Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20.516 2.18716V6.1828L22.7974 2.9995C25.9439 4.55177 28.2013 7.63253 28.6001 11.2723H7.70811C8.10691 7.63203 10.3649 4.55093 13.5121 2.99887L15.7939 6.1828V2.18676C16.5526 2.01266 17.3426 1.92068 18.1541 1.92068C18.9662 1.92068 19.7568 2.0128 20.516 2.18716ZM18.1733 22.9386L18.1541 22.9387L18.1349 22.9386H18.1733Z"
		/>
	</svg>
)

type IconProps = {
	name: string
	className?: string
}

export const Icon: React.FC<Omit<FontAwesomeIconProps, "icon"> & IconProps> = (props) => {
	const { name = "", className = "", ...rest } = props
	switch (name) {
		case "Assemblies":
		case "Assembly":
		case "Subject":
			return <Subject className={className} />
		case "Workers":
		case "Worker":
		case "Actor":
			return <Worker className={className} />
		default:
	}
	const rawIcon = Icons[name] || faSquareFull
	return <FontAwesomeIcon className={className} {...rest} icon={rawIcon} />
}
