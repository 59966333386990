import { assign, get } from "lodash-es"

const now = new Date()

const config = {
	buildCommit: "0000000000000000000000000000000000000000",
	buildTime: now.toISOString(),
	buildVersion: "v0.0.0",
	debug: false,
	env: "development",
	toggleOSBaseURL: "https://os.toggle.test",
	undoEventTimeout: 10000,
}

export default assign({}, config, get(window, "__CONFIG", {}))
