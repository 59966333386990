import React from "react"
import { delay } from "lodash-es"
import clsx from "clsx"
import {
	ExclamationCircleIcon,
	InformationCircleIcon,
	CheckCircleIcon,
	XIcon,
} from "@heroicons/react/solid"

import { useManager } from "@app/contexts"
import type { Alert as AlertType } from "@app/domain"

type AlertProps = {
	alert: AlertType
}

const Icons = {
	Error: ExclamationCircleIcon,
	Info: InformationCircleIcon,
	Success: CheckCircleIcon,
}

const Colors = {
	Error: ["bg-red-500", "text-red-300", "bg-red-600"],
	Info: ["bg-yellow-600", "text-yellow-500", "bg-yellow-700"],
	Success: ["bg-green-500", "text-green-400", "bg-green-600"],
}

export const Alert: React.FC<AlertProps> = (props) => {
	const { alert } = props

	const { removeAlert } = useManager()
	const [tick, setTick] = React.useState(0)

	const { id, timeout = 5000, sticky = false } = alert

	React.useEffect(() => {
		if (sticky) {
			return
		}

		let timeout: number

		const fire = async () => {
			setTick((prev) => prev + 250)
			schedule()
		}

		const schedule = () => {
			timeout = delay(fire, 250)
		}

		schedule()

		return () => {
			clearTimeout(timeout)
		}
	}, [id, timeout, sticky])

	React.useEffect(() => {
		if (!sticky && tick > timeout + 250) {
			removeAlert(id)
		}
	}, [id, timeout, sticky, tick])

	const width = tick >= 250 ? "w-0" : "w-full"
	const opacity = !sticky && (tick >= timeout + 250 || tick < 250) ? "opacity-0" : "opacity-100"
	const transition = sticky ? "" : `width ${timeout}ms linear`

	const Icon = Icons[alert.level]
	const [bgColor, textColor, barColor] = Colors[alert.level]

	return (
		<div
			id={`Alert-${id}`}
			className={clsx(opacity, "transition duration-250 bg-gray-900 shadow-lg pointer-events-auto")}
		>
			<div className="flex relative">
				<div className={clsx("flex p-4 items-center", bgColor)}>
					<Icon className="h-6 w-6 text-white" aria-hidden="true" />
				</div>
				<div className="flex-grow m-4">
					<h3 className={clsx("text-md font-bold", textColor)}>{alert.title}</h3>
					<div className="mt-1 text-sm text-gray-100">
						<p>{alert.text}</p>
					</div>
				</div>
				{!sticky && (
					<>
						<button
							className="flex items-center px-2 text-gray-300 hover:text-gray-500"
							onClick={(e) => {
								e.preventDefault()
								removeAlert(id)
								return false
							}}
						>
							<XIcon className="h-5 w-5" />
						</button>
						<div className="absolute left-0 bottom-0 w-full h-1 bg-black bg-opacity-50">
							<div className={clsx("h-1", width, barColor)} style={{ transition }}></div>
						</div>
					</>
				)}
			</div>
		</div>
	)
}
