import React from "react"

import { ConfigPage, RootPage } from "@app/pages"
import { BrowserRouter, Switch, Route, withRouter } from "react-router-dom"

const Observer = withRouter((props) => {
	const { history } = props

	React.useEffect(() => {
		const unlisten = history.listen(() => {
			window.scrollTo(0, 0)
		})
		return () => {
			unlisten()
		}
	}, [])

	return null
})

export const Router: React.FC = () => {
	return (
		<BrowserRouter>
			<Observer />
			<Switch>
				<>
					<Route exact={true} path="/" component={RootPage} />
					<Route exact={true} path="/config" component={ConfigPage} />
				</>
			</Switch>
		</BrowserRouter>
	)
}
