import React from "react"
import { isNumber } from "lodash-es"

interface PropsLogo {
	className?: string
	width?: number | string
	height?: number | string
}

export const Logo: React.FC<PropsLogo> = (props: PropsLogo) => {
	const { className = "" } = props
	let { width, height } = props

	if (isNumber(width)) {
		height = `${width}px`
	} else if (isNumber(height)) {
		width = `${height}px`
	}

	return (
		<svg
			className={className}
			width={width}
			height={height}
			viewBox="0 0 513 513"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="254.5" cy="256.5" r="233.5" fill="white" />
			<path
				d="M256.039 42.3221C298.475 42.3834 339.94 55.0232 375.194 78.6439C410.448 102.265 437.909 135.806 454.106 175.029C470.303 214.252 474.509 257.397 466.193 299.01C457.877 340.623 437.411 378.836 407.383 408.821C377.354 438.806 339.111 459.217 297.486 467.473C255.861 475.729 212.723 471.461 173.523 455.207C134.323 438.954 100.822 411.444 77.252 376.156C53.6823 340.868 41.1024 299.384 41.1024 256.948C41.1846 199.997 63.8659 145.407 104.165 105.166C144.465 64.9244 199.088 42.322 256.039 42.3221ZM256.039 0.948334C205.407 0.948334 155.912 15.9625 113.813 44.0921C71.714 72.2217 38.9018 112.203 19.5258 158.981C0.149769 205.759 -4.91988 257.232 4.95793 306.891C14.8357 356.551 39.2174 402.165 75.0196 437.968C110.822 473.77 156.437 498.151 206.096 508.029C255.755 517.907 307.228 512.837 354.006 493.461C400.784 474.085 440.765 441.273 468.895 399.174C497.025 357.075 512.039 307.58 512.039 256.948C512.039 189.053 485.068 123.938 437.058 75.929C389.049 27.9197 323.934 0.948334 256.039 0.948334Z"
				fill="#FB4123"
			/>
			<path d="M212.596 296.253V384.483H300.412V245.364L212.596 296.253Z" fill="#101820" />
			<path d="M379.643 150.618H132.435V219.091H379.643V150.618Z" fill="#101820" />
		</svg>
	)
}
